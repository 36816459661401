import React from 'react'
import { graphql } from 'gatsby'

import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import Gallery from '../components/Gallery'

export const InternationalPageTemplate = ({
  body,
  gallery
}) => (
  <main>
    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Gallery images={gallery} />
      </div>
    </section>
  </main>
)

const InternationalPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <InternationalPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default InternationalPage

export const pageQuery = graphql`
  query InternationalPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
      }
    }
  }
`